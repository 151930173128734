import React from "react";
import {
  SEO,
  MarkdownContent,
  BasicCarousel,
  Image,
  OutboundLink,
  FadeReveal,
} from "@bluefin/components";
import { Grid, Segment, Header, p, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ProductsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-products custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "67c0e260-9277-4603-8b28-1349c32d2989",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"rqjcoxwpaglzfyjf"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"eqxvivldmxvvbcwx"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__d44523b8-f0eb-464c-9a8a-8b75f3481967"}
                >
                  <div className={"product-section"}>
                    <Grid
                      stackable={true}
                      doubling={true}
                      columns={2}
                      reversed={"mobile"}
                    >
                      <Grid.Column width={8} className={"image-column"}>
                        <div className={"image-container"}>
                          <BasicCarousel
                            className={""}
                            slide={true}
                            size={1}
                            displayIndicators={false}
                            displayPlayPauseButton={false}
                            autoScroll={false}
                            animation={{
                              name: "blur",
                              duration: 200,
                              transitionProps: {
                                animating: { blur: "2px", opacity: 0.99 },
                                preAnimating: { blur: "2px", opacity: 0.99 },
                              },
                            }}
                            mobile={{ size: 1 }}
                            items={[
                              <div>
                                <div className={"image-overlay"} />
                                <Image
                                  src={
                                    "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/1.jpg"
                                  }
                                  alt={"The Perfect Daily Shampoo"}
                                />
                                <div className={"image-content"}>
                                  <div>
                                    <Header
                                      as={"h3"}
                                      content={"The Perfect Daily Shampoo"}
                                    />
                                  </div>
                                  <div>
                                    <p>Davines</p>
                                    <Header as={"h4"} content={"OI Shampoo"} />
                                    <Button
                                      primary={true}
                                      to={
                                        "https://shop.saloninteractive.com/store/salonish/product/6337/9801"
                                      }
                                      as={OutboundLink}
                                      content={"Shop Now"}
                                    />
                                  </div>
                                </div>
                              </div>,
                              <div>
                                <div className={"image-overlay"} />
                                <Image
                                  src={
                                    "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/2.jpg"
                                  }
                                  alt={"Rich and Buttery Conditioner"}
                                />
                                <div className={"image-content"}>
                                  <div>
                                    <Header
                                      as={"h3"}
                                      content={"Rich and Buttery Conditioner"}
                                    />
                                  </div>
                                  <div>
                                    <p>Davines</p>
                                    <Header
                                      as={"h4"}
                                      content={"OI Conditioner"}
                                    />
                                    <Button
                                      primary={true}
                                      to={
                                        "https://shop.saloninteractive.com/store/salonish/product/6319/9783"
                                      }
                                      as={OutboundLink}
                                      content={"Shop Now"}
                                    />
                                  </div>
                                </div>
                              </div>,
                              <div>
                                <div className={"image-overlay"} />
                                <Image
                                  src={
                                    "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/3.jpg"
                                  }
                                  alt={"Add Shine and Softness"}
                                />
                                <div className={"image-content"}>
                                  <div>
                                    <Header
                                      as={"h3"}
                                      content={"Add Shine and Softness"}
                                    />
                                  </div>
                                  <div>
                                    <p>Davines</p>
                                    <Header as={"h4"} content={"OI Oil"} />
                                    <Button
                                      primary={true}
                                      to={
                                        "https://shop.saloninteractive.com/store/salonish/product/6329/9793"
                                      }
                                      as={OutboundLink}
                                      content={"Shop Now"}
                                    />
                                  </div>
                                </div>
                              </div>,
                            ]}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        className={"product-details-column"}
                      >
                        <FadeReveal duration={1250} triggerOnce={true}>
                          <Header as={"h2"} content={"Our Favorite Products"} />
                          <p>
                            Looking for salon results every day? Support our
                            small business by buying recommended products
                            directly from us online. We only include brands and
                            products our Ish Dolls would use on our own hair.
                            The best of the best!
                          </p>
                          <Button
                            primary={true}
                            to={
                              "https://shop.saloninteractive.com/store/salonish"
                            }
                            as={OutboundLink}
                            content={"Browse All Products"}
                          />
                        </FadeReveal>
                      </Grid.Column>
                    </Grid>
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "767db75e-4ba0-4ada-a9ae-070491df11cf",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "4477e822-19c0-4392-b73d-35b2213a0fc2",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "028aadc7-a1c3-405b-a2cb-18dcd024b49d",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "00689908-f068-4f07-af04-d4047f6eb321",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "ffba22bc-071c-4330-be38-ec694cc1f909",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ce965513-c5a7-4d2d-a703-84a6c8cda023",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 23792 }) {
      title
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
